import { defineStore } from 'pinia'
import axios from '../services/axiosService'
import { v4 as uuidv4 } from 'uuid'

import { useVoicemailStore } from './voicemail'
import { useSessionStore } from './sessionStore'

import {
  assertIsPhoneNumberValid,
  assertIsPhoneNumberAuthorized,
  assertIsOriginDestinationAuthorized,
  parsePhoneNumberE164
} from '../helpers/phoneNumber'

import { socket } from '../services/socket.js'
import { fetchNotes } from '@/services/fetchNotes'
import { fetchCallLogs } from '@/services/fetchCallLogs'
import { fetchTasks } from '@/services/fetchTasks'
import { createNewNote } from '@/services/createNewNote'
import { saveNewTask } from '@/services/saveNewTask'
import { updateField } from '@/services/updateField'
import { updateNote } from '@/services/updateNote'
import { updateLog } from '@/services/updateLog'
import { updateCallDisposition } from '@/services/updateCallDisposition'
import { updateTask } from '@/services/updateTask'
import { fetchActivities } from '@/services/fetchActivities'
import { useContactStore } from './contactStore'
import { updatePhoneNumberField } from '@/services/updatePhoneNumberField'
import { waitUntil } from '@/helpers/waitUntil'
import { deleteNote } from '@/services/deleteNote'
import { pinNote } from '@/services/pinNote'

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const dialStatusOrder = [
  'pending',
  'canceled',
  'busy',
  'no-answer',
  'voicemail',
  'left-voicemail',
  'connected',
  'failed'
]

const dialStatusToCall = [
  'initiated',
  'ringing',
  'in-progress',
  'pending',
  'canceled',
  'busy',
  'no-answer',
  'voicemail'
]

const CALL_STATUSES = {
  queued: 'queued',
  initiated: 'initiated',
  ringing: 'ringing',
  'in-progress': 'in-progress',
  busy: 'busy',
  failed: 'failed',
  'no-answer': 'no-answer'
}

let activeCallObject = null
let device = null

export const usePhoneStore = defineStore('phone', {
  state: () => ({
    errorMessage: '',
    contactLoading: false,
    gg_activeCall: {
      isActive: false,
      callSid: null,
      contactId: null,
      call: null,
      isMuted: false
    },
    gg_contactList: [],
    test: false,
    token: null,
    device: null,
    selectedRowId: null,
    contactIdCalling: null,
    headers: [],
    columnSorted: null,
    conferenceId: uuidv4(),
    cancellingCalls: false,
    useAmdFeature: true,
    ongoingCalls: [],
    redialCanceledCalls: true,
    callsInitiated: 0,
    microphonePermission: 'prompt', // default is 'prompt', could be 'granted' or 'denied'
    authorizedCountryCodes: [],
    authorizedOriginDestinations: [],
    emptyMessage: '',
    successMessage: '',
    addContactLoading: false,
    isDialingFromModal: false,
    modalContact: null,
    isCallingFromModal: false,
    dropdownInModalOpen: false
  }),
  getters: {
    countPendingRows: (state) =>
      state.filteredRows.filter(
        (contact) => contact.dialStatus === 'pending' && !contact.wrongNumber
      ).length,
    filteredRows: (state) => state.gg_contactList.filter((row) => !row.isDeleted),
    canCall: (state) => state.microphonePermission === 'granted',
    hasSessionStarted: (state) =>
      state.filteredRows.some((contact) => contact.dialStatus !== 'pending'),
    getProgress: (state) =>
      state.filteredRows.filter((contact) => contact.dialStatus !== 'pending').length,
    isDialingOrCalling: (state) => state.gg_activeCall.isActive || state.isDialing,
    isDialing: (state) =>
      state.gg_contactList.filter((item) => item.isDialing || item.incoming).length > 0 ||
      state.isDialingFromModal,
    isMuted: (state) => (state.gg_activeCall.isActive ? state.gg_activeCall.isMuted : false),
    computeNextCanceledContactId: (state) => {
      const redialStatuses = ['pending', 'voicemail', 'canceled', 'no-answer', 'busy']

      const pendingCalls = state.filteredRows.filter((item) => {
        return (
          redialStatuses.includes(item.dialStatus) &&
          !item.wrongNumber &&
          item.callCount === 0 &&
          !item.meetingBooked
        )
      })

      const redialCalls = state.filteredRows.filter((item) => {
        const canceledCalls =
          item.dialStatus === 'canceled' && !item.wrongNumber && !item.meetingBooked

        const voicemailCalls =
          (item.dialStatus === 'voicemail' ||
            item.dialStatus === 'no-answer' ||
            item.dialStatus === 'busy') &&
          !item.wrongNumber &&
          !item.meetingBooked

        return canceledCalls || voicemailCalls
      })
      return pendingCalls.length === 0 && redialCalls[0]?.id
    },
    computeNextContactId: (state) => {
      const redialStatuses = ['pending', 'voicemail', 'canceled', 'no-answer', 'busy']

      const pendingCalls = state.filteredRows.filter((item) => {
        return (
          redialStatuses.includes(item.dialStatus) &&
          !item.wrongNumber &&
          item.callCount === 0 &&
          !item.meetingBooked
        )
      })

      const redialCalls = state.filteredRows.filter((item) => {
        const canceledCalls =
          item.dialStatus === 'canceled' && !item.wrongNumber && !item.meetingBooked

        const voicemailCalls =
          (item.dialStatus === 'voicemail' ||
            item.dialStatus === 'no-answer' ||
            item.dialStatus === 'busy') &&
          !item.wrongNumber &&
          !item.meetingBooked

        return canceledCalls || voicemailCalls
      })
      return pendingCalls[0]?.id || (state.redialCanceledCalls && redialCalls[0]?.id)
    },
    getMeetingBooked: (state) => (contactId) =>
      !!state.gg_contactList.find((item) => item.id === contactId)?.meetingBooked,
    countMeetingBooked: (state) => state.filteredRows.filter((item) => item.meetingBooked).length,
    callFromModal: (state) => state.isDialingFromModal && state.modalContact,
    getActiveCall: (state) => {
      if (state.isCallingFromModal) {
        return state.modalContact
      }
      return state.gg_contactList.find((item) => item.callSid === state.gg_activeCall.callSid)
    },
    getCurrentIntegrationName: (state) => state.gg_contactList[0]?.importSource
  },
  actions: {
    async hangupAndResume() {
      if (this.gg_activeCall.isActive) {
        console.log('hangupAndResume')
        this.endCall()
        this.selectedRowId = null

        await waitUntil(() => !this.gg_activeCall.isActive)

        this.startCallFromTable()
      }
    },
    sortByStatus() {
      const errorStatusOrder = ['country mismatch', 'not-authorized', 'invalid number', 'no number']

      this.gg_contactList = this.gg_contactList.sort((contactA, contactB) => {
        if (contactA.meetingBooked) {
          return 1
        }

        if (contactB.meetingBooked) {
          return -1
        }

        if (contactA.errorStatus && contactB.errorStatus) {
          const orderA = errorStatusOrder.indexOf(contactA.errorStatus)
          const orderB = errorStatusOrder.indexOf(contactB.errorStatus)
          return orderA - orderB
        }

        if (contactA.errorStatus) {
          return 1
        }

        if (contactB.errorStatus) {
          return -1
        }

        const customStatusA = contactA.dialStatus
        const customStatusB = contactB.dialStatus

        const orderA = dialStatusOrder.indexOf(customStatusA)
        const orderB = dialStatusOrder.indexOf(customStatusB)

        const isAInFirstGroup = dialStatusToCall.includes(customStatusA)
        const isBInFirstGroup = dialStatusToCall.includes(customStatusB)

        // Case 1: If both are in the first group, sort by callCount first and then by dialStatus
        if (isAInFirstGroup && isBInFirstGroup) {
          if (contactA.callCount === contactB.callCount) {
            return orderA - orderB
          }
          return contactA.callCount - contactB.callCount
        }

        // Case 2: If both are in the second group, sort only by dialStatus order
        if (!isAInFirstGroup && !isBInFirstGroup) {
          return orderA - orderB
        }

        // Case 3: If one is in the first group and the other in the second, first group should come first
        if (isAInFirstGroup && !isBInFirstGroup) return -1
        if (!isAInFirstGroup && isBInFirstGroup) return 1
      })
    },
    reset() {
      this.$reset()
    },
    setAuthorizedCountries(countries) {
      this.authorizedCountryCodes = countries
    },
    setAuthorizedOriginDestinations(countries) {
      this.authorizedOriginDestinations = countries
    },
    sortByKey(header) {
      this.gg_contactList.sort((contactA, contactB) => {
        if (header.sort === 'DESCENDING') {
          return contactA[header.id]?.localeCompare(contactB[header.id])
        } else {
          return contactB[header.id]?.localeCompare(contactA[header.id])
        }
      })
      header.sort = header.sort === 'DESCENDING' ? 'ASCENDING' : 'DESCENDING'
      this.columnSorted = header.id
    },
    resetSession() {
      this.gg_contactList = []
    },
    async waitForCallInitiation(contact) {
      // Wait until the dialing process completes or a callSid is assigned
      while (contact.isDialing && !contact.callSid) {
        console.log('checking if there is call sid')
        await new Promise((resolve) => setTimeout(resolve, 200)) // Poll every 200 ms
      }

      // If a callSid is present, even if isDialing is still true, proceed to cancel the call
      if (contact.callSid) {
        console.log('Cancelling established call due to contact deletion.')
        this.cancelCall(contact.callSid)
      } else {
        console.log('Dialing ended without establishing a call.')
      }
    },
    async deleteContact(contact) {
      try {
        contact.isDeleted = true

        const sessionStore = useSessionStore()

        const { data } = await axios.delete(
          `/api/sessions/${sessionStore.currentSessionId}/prospects/${contact.id}`
        )
        console.log('Contact successfully deleted')

        if (contact.isDialing) {
          console.log('Cancellation of an initiating call required.')
          // Additional logic to handle or wait for the call initiation to complete
          await this.waitForCallInitiation(contact)
        }
      } catch (error) {
        console.error('Error deleting contact:', error)
        // Handle error (e.g., show error message)
        contact.isDeleted = false
        this.errorMessage = error?.response?.data?.error || 'Could not delete the contact'
      }
    },
    async cancelCalls() {
      try {
        const sessionStore = useSessionStore()

        const { data } = await axios.post(
          `/api/calls/sessions/${sessionStore.currentSessionId}/cancel-calls`
        )
        console.log('successfully canceled calls')
      } catch (error) {
        console.error('error while cancelling calls', error)
      }
    },
    async startCallFromTable() {
      const sessionStore = useSessionStore()

      if (!sessionStore.fromPhoneNumber) {
        this.errorMessage = 'You need a phone number to start calling'
        return
      }

      console.log('this.microphonePermission', this.microphonePermission)
      if (!this.canCall) {
        console.log('Call not started because microphone has no permission')
        this.errorMessage = 'Cannot make a call, please give microphone access'
        return
      }

      this.cancellingCalls = false
      this.callsInitiated = 0

      const shouldStopToRedial = this.countPendingRows > 0

      while (!this.cancellingCalls && !this.gg_activeCall.isActive) {
        if (this.callsInitiated < sessionStore.callInParallel) {
          console.log('while loop', this.callsInitiated)
          // Compute the next contact ID to call
          const contactId = this.computeNextContactId
          if (!contactId) {
            console.log('No more valid contacts to call or duplicate contact ID encountered.')
            break // Exit loop if no more contacts are available to call
          }

          // Find the contact by ID
          const contact = this.gg_contactList.find((item) => item.id === contactId)

          if (shouldStopToRedial && contact.dialStatus !== 'pending') {
            break
          }

          // Update contact status before initiating the call
          Object.assign(contact, {
            incoming: false,
            isDialing: true,
            dialStatus: 'initiated'
          })

          // Mock/test call setup if in test mode
          if (this.test) {
            contact.call = {
              accept() {
                console.log('test:call:accept')
              },
              disconnect() {
                console.log('test:call:disconnect')
              },
              isMuted() {
                return false
              }
            }
          }

          // Start the actual call
          this.startCall(contactId, contact.phoneNumber, this.callsInitiated)

          await delay(200) // Introduce delay here

          // Increment the count of initiated calls
          this.callsInitiated += 1
          console.log('while loop after update', this.callsInitiated)
        } else {
          // If we've reached the maximum number of parallel calls, wait before trying again
          console.log('waiting 1s')
          await delay(1000) // Adjust this delay as necessary
        }
      }

      if (this.callsInitiated === 0) {
        console.log(
          'No calls were initiated. Please check the contacts list or the computeNextContactId method.'
        )
      } else {
        console.log(`${this.callsInitiated} calls have been initiated.`)
      }
    },
    startCallFromModal(contact) {
      const sessionStore = useSessionStore()

      if (!sessionStore.fromPhoneNumber) {
        this.errorMessage = 'You need a phone number to start calling'
        return
      }

      console.log('this.microphonePermission', this.microphonePermission)
      if (!this.canCall) {
        console.log('Call not started because microphone has no permission')
        this.errorMessage = 'Cannot make a call, please give microphone access'
        return
      }

      this.cancellingCalls = false

      this.isDialingFromModal = true
      this.modalContact = contact
      contact.incoming = false
      contact.dialStatus = 'initiated'
      if (this.test) {
        contact.call = {
          accept() {
            console.log('test:call:accept')
          },
          disconnect() {
            console.log('test:call:disconnect')
          },
          isMuted() {
            return false
          }
        }
      }
      this.callsInitiated += 1

      this.startOneCall(contact, contact.phoneNumber)
    },
    async toggleMeetingBooked(contactId) {
      try {
        const sessionStore = useSessionStore()

        const currentValue = this.gg_contactList.find((item) => item.id === contactId).meetingBooked
        await axios.post(`/api/prospects/${contactId}/meeting-booked`, {
          meetingBooked: !currentValue,
          sessionId: sessionStore.currentSessionId
        })
        console.log('Meeting booked status updated')

        this.gg_contactList.find((item) => item.id === contactId).meetingBooked =
          !this.gg_contactList.find((item) => item.id === contactId).meetingBooked
        this.sortByStatus()
      } catch (error) {
        console.error('Error updating meeting booked status:', error)
        // Handle error (e.g., show error message)
      }
    },
    endOneCall(callSidArg) {
      const callSid = this.gg_activeCall.callSid || callSidArg
      console.log('Ending one call for callSID', callSid)

      const url = `/api/calls/${callSid}/end-one-call`

      axios
        .post(url)
        .then((response) => {
          console.log('Call ended:', response.data)
        })
        .catch((error) => {
          console.error('Error ending call:', error)
        })
    },
    cancelCall(callSid) {
      console.log('Canceling call for callSID', callSid)

      const sessionStore = useSessionStore()

      let url
      if (this.test) {
        url = `/api/calls/sessions/${sessionStore.currentSessionId}/${callSid}/end-call/test`
      } else {
        url = `/api/calls/sessions/${sessionStore.currentSessionId}/${callSid}/end-call`
      }

      axios
        .post(url, {
          status: 'canceled'
        })
        .then((response) => {
          console.log('Call canceled:', response.data)
        })
        .catch((error) => {
          console.error('Error initiating call:', error)
        })
    },
    startIncomingCall(call) {
      if (this.gg_activeCall.isActive) {
        this.endCall(this.gg_activeCall.callSid)
      }
      this.gg_activeCall.call = call
      call.accept()
      if (this.test) {
        this.updateCallStatus(call.customParameters.callSid, 'in-progress')
      }
    },
    async startOneCall(contact, phoneNumber) {
      console.log(`Start call to ${phoneNumber}`)
      console.log('Twilio device.state', device?.state)

      if (device.state !== 'registered') {
        await this.initializedDevice()
      }

      if (!socket.connected) {
        console.error('Error: socket not connected')
      }

      const sessionStore = useSessionStore()

      try {
        if (!activeCallObject) {
          activeCallObject = await device.connect({
            params: { conferenceId: this.conferenceId }
          })
        }
      } catch (error) {
        console.error('Error connecting to Twilio:', error)
      }

      axios
        .post('/api/calls/one-call/start-call', {
          from: sessionStore.getFromNumber,
          to: phoneNumber,
          contactId: contact.id,
          conferenceId: this.conferenceId
        })
        .then(async (response) => {
          console.log('Call initiated:', response.data)

          console.log('connected call>', this.gg_activeCall)

          this.callSid = response.data.callSid

          contact.callSid = response.data.callSid
          contact.dialStatus = 'initiated'

          this.ongoingCalls.push(response.data.callSid)
        })
        .catch((error) => {
          console.error('Error initiating call:', error)

          console.log('error.response.data.error', error.response.data.error)
          if (
            error.response.data.error === 'call_not_started' ||
            error.response.data.error === 'unauthorized_different_countries' ||
            error.response.data.error === 'unauthorized_destination'
          ) {
            Object.assign(contact, {
              incoming: false,
              isDialing: false,
              dialStatus: 'not-authorized'
            })
            this.isDialingFromModal = false
            this.modalContact = null
          } else if (error.response.data.error === 'call_in_progress') {
            Object.assign(contact, {
              incoming: false,
              isDialing: false,
              dialStatus: 'pending'
            })
            this.isDialingFromModal = false
            this.modalContact = null
          }
        })
    },
    async startCall(contactId, phoneNumber) {
      console.log(`Start call to ${phoneNumber}`)
      console.log('Twilio device', device)
      console.log('Twilio device.state', device?.state)

      if (device.state !== 'registered') {
        await this.initializedDevice()
      }

      console.log('socket.connected', socket.connected)
      if (!socket.connected) {
        console.error('Error: socket not connected')
      }

      const voicemailStore = useVoicemailStore()

      const sessionStore = useSessionStore()

      let url
      if (this.test) {
        url = '/api/calls/start-call/test'
        setTimeout(() => {
          this.gg_activeCall.call = {
            disconnect() {
              console.log('test:call:disconnect')
            },
            isMuted() {
              return false
            }
          }
        }, 2000)
      } else {
        url = '/api/calls/start-call'
      }

      if (this.test) {
        activeCallObject = {
          disconnect() {
            console.log('test:call:disconnect')
          },
          isMuted() {
            return false
          }
        }
      } else {
        try {
          // this.gg_activeCall.call
          if (!activeCallObject) {
            activeCallObject = await device.connect({
              params: { conferenceId: this.conferenceId }
            })
          }
        } catch (error) {
          console.error('111error connecting to Twilio:', error)
          // detect expiration token error
          // await this.initializedDevice()
          // this.gg_activeCall.call = await device.connect({
          //   params: { conferenceId: response.data.conferenceId }
          // })
        }
      }

      axios
        .post(url, {
          from: sessionStore.getFromNumber,
          to: phoneNumber,
          contactId,
          callDirection: 'outgoing',
          voicemailId: voicemailStore.voicemailSelected?.messageId,
          conferenceId: this.conferenceId,
          sessionId: sessionStore.currentSessionId.toString(),
          useAmdFeature: this.useAmdFeature,
          canceledForTest: this.callsInitiated % sessionStore.callInParallel === 0 ? true : false
        })
        .then(async (response) => {
          console.log('Call initiated:', response.data)

          console.log('connected call>', this.gg_activeCall)

          this.callSid = response.data.callSid

          this.gg_contactList.find((item) => item.id === contactId).callSid = response.data.callSid
          this.gg_contactList.find((item) => item.id === contactId).dialStatus = 'initiated'

          this.ongoingCalls.push(response.data.callSid)
        })
        .catch((error) => {
          console.error('Error initiating call:', error)

          console.log('error.response.data.error', error.response.data.error)
          if (
            error.response.data.error === 'call_not_started' ||
            error.response.data.error === 'unauthorized_different_countries' ||
            error.response.data.error === 'unauthorized_destination'
          ) {
            const contact = this.gg_contactList.find((item) => item.id === contactId)

            Object.assign(contact, {
              incoming: false,
              isDialing: false,
              dialStatus: 'not-authorized'
            })

            this.callsInitiated -= 1
          } else if (error.response.data.error === 'call_in_progress') {
            const contact = this.gg_contactList.find((item) => item.id === contactId)

            Object.assign(contact, {
              incoming: false,
              isDialing: false,
              dialStatus: 'pending'
            })

            this.callsInitiated -= 1
          }
        })
    },
    endCall() {
      const sessionStore = useSessionStore()

      let url
      const callSid = this.gg_activeCall.callSid
      if (this.test) {
        url = `/api/calls/sessions/${sessionStore.currentSessionId}/${callSid}/end-call/test`
      } else {
        url = `/api/calls/sessions/${sessionStore.currentSessionId}/${callSid}/end-call`
      }

      let status
      if (this.gg_activeCall.isActive) {
        status = 'completed'
      } else {
        status = 'canceled'
      }

      axios
        .post(url, {
          status,
          sessionId: sessionStore.currentSessionId
        })
        .then((response) => {
          console.log('Call end:', response.data)
        })
        .catch((error) => {
          console.error('Error initiating call:', error)
        })
    },
    updateTranscription({ callSid, data, isFinal }) {
      const contact = this.gg_contactList.find((item) => item.callSid === callSid)
      if (!contact) {
        return
      }
      if (isFinal) {
        contact.transcription += ` ${data}`
        contact.tempTranscription = ''
      } else {
        contact.tempTranscription = data
      }
    },
    resetTranscription(callSid) {
      const contact = this.gg_contactList.find((item) => item.callSid === callSid)
      if (!contact) {
        console.error("reset transcription error: could't find contact by call sid")
        return
      }
      contact.transcription = ''
      contact.tempTranscription = ''
    },
    updateCallStatusFromCallback(prospectId, sessionId, callStatus) {
      const sessionStore = useSessionStore()

      if (!sessionStore.currentSessionId || sessionStore.currentSessionId !== sessionId) {
        console.log('callback is not in this session')
        return
      }

      const prospect = this.gg_contactList.find((item) => item.id === prospectId)

      if (!prospect) {
        console.error("couldn't find the prospect")
        return
      }

      prospect.dialStatus = callStatus
    },
    async updateCallStatusOneCall(callSid, status) {
      console.log('updateCAllStatusOneCall', { callSid, status })
      if (status === 'initiated') {
        return
      }
      const onGoingCall = this.modalContact

      if (!onGoingCall) {
        console.log('No onGoingCall with this call SID')
        return
      }

      if (status === 'logged-to-providers') {
        onGoingCall.callSid = null
        this.modalContact = null
        this.isCallingFromModal = false

        onGoingCall.callLogs = onGoingCall.callLogs.filter((callLog) => !callLog.onGoingCall)
        // fetch only if modal open
        if (this.selectedRowId === onGoingCall.id) {
          await fetchCallLogs(onGoingCall)
          if (onGoingCall.tempCallLogNote) {
            // update the newly created call log note, with the tempCallLogNote
            const lastLogId = onGoingCall.callLogs.filter((callLog) => !callLog.onGoingCall)[0].id
            updateLog(onGoingCall, lastLogId, onGoingCall.tempCallLogNote)
          }
        }

        return
      }

      onGoingCall.dialStatus = status

      console.log('onGoingCall', onGoingCall)

      switch (status) {
        case 'in-progress':
          if (this.test) {
            this.gg_activeCall.isActive = true
          }
          this.gg_activeCall.callSid = callSid
          this.gg_activeCall.contactId = onGoingCall.id
          this.gg_activeCall.isActive = true
          this.isCallingFromModal = this.isDialingFromModal
          this.isDialingFromModal = false
          onGoingCall.isDialing = false
          onGoingCall.callLogs.unshift({
            onGoingCall: true,
            date: new Date(),
            body: '',
            callDisposition: 'connected',
            direction: 'OUTBOUND',
            type: 'call',
            importSource: onGoingCall.importSource
          })
          break
        case 'completed':
        case 'connected':
        case 'voicemail':
        case 'left-voicemail':
        case 'canceled':
        case 'no-answer':
        case 'busy':
        case 'failed':
          if (this.gg_activeCall.callSid === callSid) {
            this.gg_activeCall.isActive = false
            this.gg_activeCall.callSid = null
            this.gg_activeCall.call = null
          }

          if (this.ongoingCalls.indexOf(callSid) > -1) {
            this.ongoingCalls.splice(this.ongoingCalls.indexOf(callSid), 1)
          }

          if (activeCallObject) {
            console.log('Disconnecting call')
            activeCallObject.disconnect()
            activeCallObject = null
          }

          this.isDialingFromModal = false
          onGoingCall.isDialing = false
          onGoingCall.incoming = false
          onGoingCall.call = null

          onGoingCall.callCount += 1

          if (status === 'canceled') {
            onGoingCall.canceledCallsCount += 1
          }
          this.sortByStatus()
          break
      }
    },
    async updateCallStatus(callSid, status) {
      console.log('updateCAllStatus', { callSid, status })
      if (status === 'initiated') {
        return
      }
      const onGoingCall = this.gg_contactList.find((item) => item.callSid === callSid)
      if (!onGoingCall) {
        console.log('No onGoingCall with this call SID')
        return
      }
      if (status === 'logged-to-providers') {
        this.resetTranscription(callSid)
        onGoingCall.callSid = null

        onGoingCall.callLogs = onGoingCall.callLogs.filter((callLog) => !callLog.onGoingCall)
        // fetch only if modal open
        if (this.selectedRowId === onGoingCall.id) {
          await this.fetchCallLogs(onGoingCall.id)
          if (onGoingCall.tempCallLogNote) {
            // update the newly created call log note, with the tempCallLogNote
            const lastLogId = onGoingCall.callLogs.filter((callLog) => !callLog.onGoingCall)[0].id
            updateLog(onGoingCall, lastLogId, onGoingCall.tempCallLogNote)
          }
        }
        return
      }

      if (status === 'in-progress-unconnected') {
        onGoingCall.dialStatus = 'in-progress'
      } else {
        onGoingCall.dialStatus = status
      }

      console.log('onGoingCall', onGoingCall)

      switch (status) {
        case 'in-progress':
          if (this.test) {
            this.gg_activeCall.isActive = true
          }
          this.gg_activeCall.callSid = callSid
          this.gg_activeCall.contactId = onGoingCall.id
          this.gg_activeCall.isActive = true
          onGoingCall.isDialing = false
          this.openRowModal(this.gg_contactList.find((item) => item.id === onGoingCall.id))
          // log call in the front during a call, to be able to take note
          this.gg_contactList
            .find((item) => item.id === onGoingCall.id)
            .callLogs.unshift({
              onGoingCall: true,
              date: new Date(),
              body: '',
              callDisposition: 'connected',
              direction: 'OUTBOUND',
              type: 'call',
              importSource: onGoingCall.importSource
            })
          break
        case 'completed':
        case 'connected':
        case 'voicemail':
        case 'left-voicemail':
        case 'canceled':
        case 'no-answer':
        case 'busy':
        case 'failed':
          if (this.gg_activeCall.callSid === callSid) {
            this.gg_activeCall.isActive = false
            this.gg_activeCall.callSid = null
            this.gg_activeCall.call = null
          }

          if (this.ongoingCalls.indexOf(callSid) > -1) {
            this.ongoingCalls.splice(this.ongoingCalls.indexOf(callSid), 1)
          }

          if (this.gg_activeCall.callSid === callSid || this.ongoingCalls.length === 0) {
            if (activeCallObject) {
              console.log('Disconnecting call')
              activeCallObject.disconnect()
              activeCallObject = null
            }
          }
          onGoingCall.isDialing = false
          onGoingCall.incoming = false
          onGoingCall.call = null

          onGoingCall.callCount += 1

          if (onGoingCall.callCountPerNumber[parsePhoneNumberE164(onGoingCall.phoneNumber)]) {
            onGoingCall.callCountPerNumber[parsePhoneNumberE164(onGoingCall.phoneNumber)] += 1
          } else {
            onGoingCall.callCountPerNumber[parsePhoneNumberE164(onGoingCall.phoneNumber)] = 1
          }

          this.callsInitiated -= 1
          console.log('completed', this.callsInitiated)

          if (status === 'canceled') {
            onGoingCall.canceledCallsCount += 1
          }
          this.sortByStatus()
          break
      }
    },
    async checkMicrophonePermission() {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' })
        this.microphonePermission = permissionStatus.state
        permissionStatus.onchange = () => {
          this.microphonePermission = permissionStatus.state
        }
      } catch (error) {
        console.error('Error checking microphone permission:', error)
      }
    },

    async requestMicrophoneAccess() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true })
        this.microphonePermission = 'granted'
      } catch (error) {
        console.error('Access denied for microphone:', error)
        this.microphonePermission = 'denied'
      }
    },
    async initializedDevice() {
      try {
        await this.checkMicrophonePermission()
        if (this.microphonePermission !== 'granted') {
          await this.requestMicrophoneAccess()
        }

        console.log('Reinitializing twilio device')
        const { data } = await axios.get(`/api/calls/twilio/token`)

        this.token = data.token

        if (device) {
          device.updateToken(this.token)
          console.log('Twilio device reinitialized with new token')
          if (device.state !== 'registered') {
            device.register()
          }
        } else {
          device = new Twilio.Device(this.token)

          device.on('incoming', (call) => {
            console.log('call incoming from Twilio')
            if (call.customParameters.incoming) {
              const contact = this.gg_contactList.find(
                (item) => item.id === call.customParameters.contact.Id
              )
              if (contact) {
                contact.callSid = call.customParameters.callSid
                contact.dialStatus = 'ringing'
                contact.incoming = true
                contact.call = call
              } else {
                // handle unknown number calling
              }
            } else {
              this.gg_activeCall.call = call
              // test start
              this.gg_activeCall.isActive = true
              // test end
              call.accept()
              console.log('call accepted')
            }
          })

          device.on('disconnect', (connection) => {
            // Perform cleanup here
            console.log('Twilio device disconnect event')
            this.gg_activeCall.isActive = false
            this.gg_activeCall.call = null
          })

          device.on('error', (error) => {
            console.error('Twilio device error: ' + error.message)
          })

          device.on('tokenWillExpire', () => {
            console.log('tokenWillExpire')
            if (!this.isDialingOrCalling) {
              this.initializedDevice()
            }
            // device.updateToken(token)
          })

          device.on('registered', () => {
            console.log('Twilio device registered event')
          })

          device.on('unregistered', () => {
            console.log('Twilio device unregistered event')
          })

          device.register()
        }
        console.log('Twilio device', device)
        console.log('Twilio device.state', device.state)
      } catch (error) {
        console.error('Error reinitializing twilio device:', error)
      }
    },
    muteCall() {
      // Add logic to mute the call for the specific row
      if (this.gg_activeCall.isActive) {
        this.gg_activeCall.isMuted = !activeCallObject.isMuted()
        activeCallObject.mute(!activeCallObject.isMuted())
      }
    },
    hangUpCall() {
      // Add logic to hang up the call for the specific row
      if (this.gg_activeCall.isActive) {
        activeCallObject.disconnect()
      }
    },
    async deleteAllActivities() {
      try {
        const { data } = await axios.delete(
          `/api/prospects/${this.gg_contactList[1].sourceId}/activities`
        )
        console.log('Activities deleted')
      } catch (error) {
        console.error('Error deleting activities:', error)
      }
    },
    async openRowModal(row) {
      this.selectedRowId = row.id

      await this.fetchActivities(row.id)
    },
    async fetchActivities(contactId) {
      const contact = this.gg_contactList.find((item) => item.id === contactId)
      await fetchActivities(contact)
    },
    async fetchNotes(contactId) {
      const contact = this.gg_contactList.find((item) => item.id === contactId)
      await fetchNotes(contact)
    },
    async fetchTasks(contactId) {
      const contact = this.gg_contactList.find((item) => item.id === contactId)
      await fetchTasks(contact)
    },
    async fetchCallLogs(contactId) {
      const contact = this.gg_contactList.find((item) => item.id === contactId)
      await fetchCallLogs(contact)
    },
    async updateField(contact, { id, label }, value) {
      try {
        if (id === 'phoneNumber') {
          const sessionStore = useSessionStore()
          await updatePhoneNumberField(contact, sessionStore.phoneNumberFieldSelected.id, value)
          this.computeNumberStatuses()
          this.sortByStatus()
        } else {
          await updateField(contact, id, value)
        }
      } catch (error) {
        this.errorMessage = `Error updating prospect: ${label.toLowerCase()}`
      }
    },
    async updateCallDisposition(contactId, logId, callDisposition) {
      try {
        const contact = this.gg_contactList.find((item) => item.id === contactId)
        await updateCallDisposition(contact, logId, callDisposition)
        await fetchCallLogs(contact)
        this.sortByStatus()
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating call disposition`
      }
    },
    async updateLog(contactId, logId, body) {
      try {
        const contact = this.gg_contactList.find((item) => item.id === contactId)
        if (logId) {
          await updateLog(contact, logId, body)
        } else {
          contact.tempCallLogNote = body
        }
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating log`
      }
    },
    async updateNote(contactId, noteId, body) {
      try {
        const contact = this.gg_contactList.find((item) => item.id === contactId)
        await updateNote(contact, noteId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating note`
      }
    },
    async deleteNote(contactId, noteId) {
      try {
        const contact = this.gg_contactList.find((item) => item.id === contactId)
        await deleteNote(contact, noteId)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error deleting note`
      }
    },
    async pinNote(contactId, noteId) {
      try {
        const contact = this.gg_contactList.find((item) => item.id === contactId)
        await pinNote(contact, noteId)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error pinning note`
      }
    },
    async updateTask(contactId, taskId, taskField, taskValue) {
      try {
        const contact = this.gg_contactList.find((item) => item.id === contactId)
        await updateTask(contact, taskId, taskField, taskValue)
      } catch (error) {
        console.log('error updating task', error)
        this.errorMessage = `Error updating task`
      }
    },
    async saveNewTask(contactId, task) {
      try {
        await saveNewTask(contactId, task)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating task`
      }
    },
    async createNewNote(contactId) {
      try {
        await createNewNote(contactId)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating note`
      }
    },
    buildHeaders(prospect, customFields) {
      return [
        { id: 'dialStatus', label: 'Dial status', sort: 'DESCENDING' },
        { id: 'firstName', label: 'First name', sort: 'DESCENDING', hideInModal: true },
        { id: 'contactUrl', label: 'Contact url', sort: 'DESCENDING', hidden: true },
        {
          id: 'companyUrl',
          label: 'Company url',
          sort: 'DESCENDING',
          hidden: true
        },
        { id: 'lastName', label: 'Last name', sort: 'DESCENDING', hideInModal: true },
        { id: 'title', label: 'Title', sort: 'DESCENDING', hideInModal: true },
        {
          id: 'company',
          label: 'Company',
          sort: 'DESCENDING',
          hideInModal: true,
          nonEditable: prospect?.importSource === 'hubspot'
        },
        {
          id: 'taskName',
          label: 'Task',
          sort: 'DESCENDING',
          hidden: !prospect.taskName,
          nonEditable: true
        },
        { id: 'email', label: 'Email', sort: 'DESCENDING', hideInTable: true },
        { id: 'status', label: 'Status', sort: 'DESCENDING', nonEditable: true },
        { id: 'phoneNumber', label: 'Phone number', sort: 'DESCENDING' },
        {
          id: 'dealName',
          label: 'Deal',
          sort: 'DESCENDING',
          nonEditable: prospect?.importSource === 'hubspot',
          hideInTable: true,
          hidden: prospect?.importSource !== 'hubspot'
        },
        {
          id: 'dealUrl',
          label: 'Deal url',
          sort: 'DESCENDING',
          hidden: true
        },
        {
          id: 'ownerId',
          label: 'Contact owner',
          sort: 'DESCENDING',
          hideInTable: true,
          hidden: prospect?.importSource !== 'hubspot'
        },
        // { id: 'description', label: 'Note', sort: 'DESCENDING' },
        ...customFields.map((column) => ({
          ...column,
          sort: 'DESCENDING',
          nonEditable: true
        }))
      ]
    },
    setPhoneNumberField(phoneNumberField) {
      this.gg_contactList.forEach((contact) => {
        contact.phoneNumber = contact.phoneNumbers[phoneNumberField.id]
      })
      this.headers.find((header) => header.id === 'phoneNumber').label = phoneNumberField.label
    },
    async initContactList() {
      const sessionStore = useSessionStore()

      if (!sessionStore.currentSessionId) {
        return
      }

      console.log('fetching prospect list')
      const { data } = await axios.get(
        `/api/sessions/${sessionStore.currentSessionId}/prospects`,
        {}
      )

      this.headers = this.buildHeaders(data.prospects[0], data.customFields)

      this.gg_contactList = data.prospects

      const contactStore = useContactStore()

      contactStore.leadStatuses = data.leadStatuses

      sessionStore.phoneNumberList = data.phoneNumberList
      sessionStore.phoneNumberFieldSelected = data.phoneNumberFieldSelected
      this.setPhoneNumberField(sessionStore.phoneNumberFieldSelected)

      contactStore.setCallDispositionList(data.callDispositionList)
      contactStore.taskTypeList = data.taskTypeList

      this.computeNumberStatuses()
      this.sortByStatus()

      contactStore.fetchContactOwners()
    },
    checkPhoneNumber(contact) {
      if (!contact.phoneNumber) {
        contact.errorStatus = 'no number'
        contact.wrongNumber = true
        return
      }
      try {
        assertIsPhoneNumberValid(contact.phoneNumber)
      } catch (error) {
        contact.errorStatus = 'invalid number'
        contact.wrongNumber = true
        return
      }

      try {
        assertIsPhoneNumberAuthorized(contact.phoneNumber, this.authorizedCountryCodes)
      } catch (error) {
        contact.errorStatus = 'not-authorized'
        contact.wrongNumber = true
        return
      }

      try {
        const sessionStore = useSessionStore()

        assertIsOriginDestinationAuthorized(
          sessionStore.getFromNumber,
          contact.phoneNumber,
          this.authorizedOriginDestinations
        )
      } catch (error) {
        console.log(error)
        contact.errorStatus = 'country mismatch'
        contact.wrongNumber = true
        return
      }
      contact.errorStatus = ''
      contact.wrongNumber = false
    },
    computeCallCountPerNumber({ callCountPerNumber, phoneNumber }) {
      try {
        const parsedPhoneNumber = parsePhoneNumberE164(phoneNumber)

        return callCountPerNumber[parsedPhoneNumber] || 0
      } catch (error) {
        return 0
      }
    },
    computeNumberStatuses() {
      const copy = JSON.parse(JSON.stringify(this.gg_contactList))
      for (const contact of copy) {
        contact.callCount = this.computeCallCountPerNumber(contact)
        this.checkPhoneNumber(contact)
      }
      this.gg_contactList = copy
    }
  }
})
